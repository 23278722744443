<template>
    <div class="view pa24">
        <div class="d-flex">
            <div>
                <!-- <el-input class="w200 mb10 mr10" placeholder="请输入关键字" v-model="searchData.name" />
                <el-button type="primary" @click="searchFun">搜索</el-button> -->
                <!-- <el-button type="primary">导出数据</el-button> -->
            </div>
            <div style="margin-left: auto">

            </div>
        </div>
        <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
            <template v-slot:table>
                <el-table-column type="index" align="center" label="序号" />
                <el-table-column prop="number" align="center" label="房屋/单元" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.houseName }}/{{ scope.row.houseNumber }}单元
                    </template>
                </el-table-column>
                <el-table-column prop="userName" align="center" label="预约用户" show-overflow-tooltip />
                <el-table-column prop="userPhone" align="center" label="联系方式" show-overflow-tooltip />
                <el-table-column prop="number" align="center" width="250" label="预约时间" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.subscribeDate+" "+scope.row.startTime }} ~ {{scope.row.endTime }}
                    </template>
                </el-table-column>
                <el-table-column prop="statusTitle" align="center" label="预约状态" show-overflow-tooltip />
                <el-table-column align="center" label="操作" width="80">
                    <template slot-scope="scope">
                        <el-button type="text" @click="updateData(scope.row)">编辑状态</el-button>
                    </template>
                </el-table-column>
            </template>
        </commonTable>

    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { updatePropertySubscribe, getPropertySubscribeList } from "@/api/propertyServices";
export default {
    name: "meetingTable",
    components: {
        commonTable,
    },
    data() {
        return {
            houseId: 0,
            parkId: 0,
            //列表配置
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            tableData: [],
            searchData: { name: "" },
            userInfo: this.$store.state.loginRoot.userInfo,
            statusData: {
                0: "已预约", 1: "已完成", 2: "已错过", 3: "已取消"
            }
        };
    },
    filters: {

    },
    computed: {
        watchCheckedPark() {
            return this.$store.state.checkedPark
        }
    },
    watch: {
        $route: {
            handler() {
                this.houseId = this.$route.query.houseId;
                this.getList();
            }, deep: true,
            immediate: true,
        },
    },
    async created() {
        let checkedPark = sessionStorage.getItem('checkedPark');
        this.parkId = JSON.parse(checkedPark).parkId;
        this.getList();
    },
    methods: {
        /**@method 搜索 */
        searchFun() {
            this.getList();
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                parkId: this.parkId
            };
            if (this.houseId) {
                params.houseId = this.houseId
            }
            try {
                this.loading = true;
                let res = await getPropertySubscribeList(params);
                this.loading = false;

                const { data } = res;
                let tableData = [];
                for (let row of data.pageInfo.list) {

                    row.statusTitle = statusData[row.status]
                    tableData.push(row);
                }
                this.tableData = tableData;
                this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        updateData(row) {
            this.$confirm('会议室预约', '提示', {
                confirmButtonText: '会议已完成',
                cancelButtonText: '取消预约',
                type: 'warning'
            }).then(() => {
                updatePropertySubscribe({ subscribeId: row.subscribeId,status:1 }).then(res => {
                    this.$message({
                        type: 'success',
                        message: '会议已完成'
                    });
                }).catch(err => {
                    if (err.code === 201) {
                        this.$message({
                            type: 'error',
                            message: err.message
                        });
                    }
                })

            }).catch(() => {
                updatePropertySubscribe({ subscribeId: row.subscribeId,status:3 }).then(res => {
                    this.$message({
                        type: 'info',
                        message: '已取消预约'
                    });
                }).catch(err => {
                    if (err.code === 201) {
                        this.$message({
                            type: 'error',
                            message: err.message
                        });
                    }
                })
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>